<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2> Screening Antibody - List</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
            <router-link to="/dashboards/add-screening-antibody">
            <b-button variant="primary-2">
              <i class="ri-add-line" /> Tambah Screening Antibody
            </b-button>
          </router-link>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            >
            </b-form-input>
          </b-input-group>
        </div>
      </div>
    </b-col>
    <div class="col-12">
      <b-table
          style="overflow: auto; white-space: nowrap;"
          :items="formattedItems"
          :fields="fields"
          :busy.sync="isBusy"
          responsive="sm"
      >
        <template v-slot:cell(Aksi)="row">
          <router-link :to="`/dashboards/edit-screening-antibody/${row.item.id}`">
            <b-button
                class="mx-1"
                variant="info-1"
            >
              <i class="ri-edit-box-line mx-2" />
              Edit
            </b-button>
          </router-link>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @input="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BButton, BButtonGroup, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BSpinner, BTable, BPagination,
} from 'bootstrap-vue'
import tableDataMixins from '../../../../mixins/tableDataMixins'
export default {
  name: 'listscreeningantibodys',
  data() {
    return {
      table: {
        items: [
          { 
            id: '',
            barcode:'',
            antibody: '', 
            updated_at: '',
          },
        ],
      },
      fields: [
        {
          label: 'Kode Kantong',
          key: 'barcode',
          sortable: true,
        },
        {
          label: 'Antibody',
          key: 'combinedInput',
          sortable: true,
        },
        {
          label: 'Update Terakhir',
          key: 'updated_at',
          sortable: true,
          },
        {
          key: 'Aksi',
          sortable: false,
        },
      ],
    }
  },
  components: {
    BSpinner,
    BButtonGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BRow,
    BInputGroup,
    BCol,
    BTable,
    BPagination,
  },
  mixins: [tableDataMixins],
  computed: {
    screeningAntibodys() {
      return this.$store.state.manageScreeningAntibody.screeningAntibodys
    }, 
    totalScreeningAntibodys() {
      return this.$store.state.manageScreeningAntibody.totalPages;
    },
    isBusy() {
      return this.$store.state.manageScreeningAntibody.isBusy;
    },
    formattedItems() {
      return this.table.items.map(item => {
        const [datePart,] = item.updated_at.split("T");
        const [year, month, day] = datePart.split("-");
        const formatDateLocal = `${day}-${month}-${year}`;
        return {
          combinedInput: 
            `${this.convertToBoolean(item.antibody)}`,
          id: item.id,
          barcode: item.barcode,
          updated_at: formatDateLocal 
        };
      });
    },

  },
  methods: {
    async fetchData() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch('manageScreeningAntibody/getScreeningAntibodys', {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
      });
      this.table.items = await this.screeningAntibodys;
      this.table.total = await this.totalScreeningAntibodys;
    },
    convertToBoolean(value ) {
        return value === 1 ? 'Positif' : 'Negatif' ;
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)
      this.table.debounceSearch = setTimeout(() => {
      this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData();
  },
}
</script>

<style scoped>

</style>



